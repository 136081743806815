import * as React from "react";
import {useEffect, useState} from "react";
import Login from "../components/modules/Login";
import Campaigns from "../components/modules/campaigns";
import Layout from "../components/layout";
import {Helmet} from "react-helmet";

export default function StudiesPage() {

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken || accessToken === 'undefined') {
            setIsLoggedIn(false);
        }else{
            setIsLoggedIn(true);
        }
    }

    useEffect(() => {
        checkUserToken();
    }, [isLoggedIn]);

    return (
        <React.Fragment>
            {!isLoggedIn && <Login/>}

            {isLoggedIn &&
            <>
                <Helmet>
                    <title>Glassview Origin - Campaigns</title>
                </Helmet>
                <Layout>
                    <Campaigns/>
                </Layout>
            </>
            }

        </React.Fragment>
    )
}
